"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogType = void 0;
/**
 * Enum describing various types of logs.
 */
var LogType;
(function (LogType) {
    LogType["COMPLETE"] = "hundo";
    LogType["MEMBER_JOIN"] = "memberJoin";
    LogType["MEMBER_LEAVE"] = "memberLeave";
    LogType["GAME_TIER_CHANGE"] = "gameTierChange";
    LogType["GAME_ADD"] = "gameCurate";
    LogType["GAME_REMOVE"] = "gameRemove";
    LogType["BADGE_CREATE"] = "badgeCreate";
    LogType["BADGE_GET"] = "badgeGrant";
    LogType["ACHIEVEMENTS_CHANGE"] = "achievementNumberChange";
    LogType["CUSTOM"] = "custom";
})(LogType = exports.LogType || (exports.LogType = {}));
