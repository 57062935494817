"use strict";
/**
 * @module Update
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUpdateStatus = exports.updateMember = void 0;
var updateMember_1 = require("./updateMember");
Object.defineProperty(exports, "updateMember", { enumerable: true, get: function () { return updateMember_1.updateMember; } });
var getUpdateStatus_1 = require("./getUpdateStatus");
Object.defineProperty(exports, "getUpdateStatus", { enumerable: true, get: function () { return getUpdateStatus_1.getUpdateStatus; } });
