import { DashboardTileFeatured } from './DashboardTileFeatured';
import { DashboardTileBadges } from './DashboardTileBadges';
import { DashboardTileDiscord } from './DashboardTileDiscord';
import { DashboardTileGames } from './DashboardTileGames';
import { DashboardTileHistory } from './DashboardTileHistory';
import { DashboardTileMembers } from './DashboardTileMembers';
import { DashboardTileSale } from './DashboardTileSale';
import { DashboardTileTop } from './DashboardTileTop';
import { DashboardTileTrivia } from './DashboardTileTrivia';

export const DashboardTile = {
  Featured: DashboardTileFeatured,
  Badges: DashboardTileBadges,
  Games: DashboardTileGames,
  Discord: DashboardTileDiscord,
  History: DashboardTileHistory,
  Members: DashboardTileMembers,
  Sale: DashboardTileSale,
  Top: DashboardTileTop,
  Trivia: DashboardTileTrivia,
};
