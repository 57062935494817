"use strict";
/**
 * @module Members
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.revokeAwardFromMemberById = exports.giveAwardToMemberById = exports.getMemberAwardList = exports.revokeCheeseFromMemberById = exports.giveCheeseToMemberById = exports.getMemberCheeseList = exports.revokeBadgeFromMemberById = exports.giveBadgeToMemberById = exports.getMemberBadgeList = exports.getMemberAchievementList = exports.getMemberGameList = exports.updateMemberById = exports.getMemberById = exports.getMembersList = void 0;
// Basic member methods
var getMembersList_1 = require("./getMembersList");
Object.defineProperty(exports, "getMembersList", { enumerable: true, get: function () { return getMembersList_1.getMembersList; } });
var getMemberById_1 = require("./getMemberById");
Object.defineProperty(exports, "getMemberById", { enumerable: true, get: function () { return getMemberById_1.getMemberById; } });
var updateMemberById_1 = require("./updateMemberById");
Object.defineProperty(exports, "updateMemberById", { enumerable: true, get: function () { return updateMemberById_1.updateMemberById; } });
// Game related member endpoints
var getMemberGameList_1 = require("./getMemberGameList");
Object.defineProperty(exports, "getMemberGameList", { enumerable: true, get: function () { return getMemberGameList_1.getMemberGameList; } });
var getMemberAchievementList_1 = require("./getMemberAchievementList");
Object.defineProperty(exports, "getMemberAchievementList", { enumerable: true, get: function () { return getMemberAchievementList_1.getMemberAchievementList; } });
// Badges related member endpoints
var getMemberBadgeList_1 = require("./getMemberBadgeList");
Object.defineProperty(exports, "getMemberBadgeList", { enumerable: true, get: function () { return getMemberBadgeList_1.getMemberBadgeList; } });
var giveBadgeToMemberById_1 = require("./giveBadgeToMemberById");
Object.defineProperty(exports, "giveBadgeToMemberById", { enumerable: true, get: function () { return giveBadgeToMemberById_1.giveBadgeToMemberById; } });
var revokeBadgeFromMemberById_1 = require("./revokeBadgeFromMemberById");
Object.defineProperty(exports, "revokeBadgeFromMemberById", { enumerable: true, get: function () { return revokeBadgeFromMemberById_1.revokeBadgeFromMemberById; } });
// Cheese related member endpoints
var getMemberCheeseList_1 = require("./getMemberCheeseList");
Object.defineProperty(exports, "getMemberCheeseList", { enumerable: true, get: function () { return getMemberCheeseList_1.getMemberCheeseList; } });
var giveCheeseToMemberById_1 = require("./giveCheeseToMemberById");
Object.defineProperty(exports, "giveCheeseToMemberById", { enumerable: true, get: function () { return giveCheeseToMemberById_1.giveCheeseToMemberById; } });
var revokeCheeseFromMemberById_1 = require("./revokeCheeseFromMemberById");
Object.defineProperty(exports, "revokeCheeseFromMemberById", { enumerable: true, get: function () { return revokeCheeseFromMemberById_1.revokeCheeseFromMemberById; } });
// Awards related member endpoints
var getMemberAwardList_1 = require("./getMemberAwardList");
Object.defineProperty(exports, "getMemberAwardList", { enumerable: true, get: function () { return getMemberAwardList_1.getMemberAwardList; } });
var giveAwardToMemberById_1 = require("./giveAwardToMemberById");
Object.defineProperty(exports, "giveAwardToMemberById", { enumerable: true, get: function () { return giveAwardToMemberById_1.giveAwardToMemberById; } });
var revokeAwardFromMemberById_1 = require("./revokeAwardFromMemberById");
Object.defineProperty(exports, "revokeAwardFromMemberById", { enumerable: true, get: function () { return revokeAwardFromMemberById_1.revokeAwardFromMemberById; } });
