"use strict";
/**
 * @module Logs
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLogList = exports.createLog = void 0;
var createLog_1 = require("./createLog");
Object.defineProperty(exports, "createLog", { enumerable: true, get: function () { return createLog_1.createLog; } });
var getLogList_1 = require("./getLogList");
Object.defineProperty(exports, "getLogList", { enumerable: true, get: function () { return getLogList_1.getLogList; } });
