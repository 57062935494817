"use strict";
/**
 * @module Races
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RaceType = void 0;
var RaceType;
(function (RaceType) {
    RaceType["TIME_BASED"] = "time";
    RaceType["SCORE_BASED"] = "score";
})(RaceType = exports.RaceType || (exports.RaceType = {}));
