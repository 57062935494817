"use strict";
/**
 * @module Badges
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteBadgeById = exports.updateBadgeById = exports.getBadgeById = exports.createBadge = exports.getBadgesList = void 0;
var getBadgesList_1 = require("./getBadgesList");
Object.defineProperty(exports, "getBadgesList", { enumerable: true, get: function () { return getBadgesList_1.getBadgesList; } });
var createBadge_1 = require("./createBadge");
Object.defineProperty(exports, "createBadge", { enumerable: true, get: function () { return createBadge_1.createBadge; } });
var getBadgeById_1 = require("./getBadgeById");
Object.defineProperty(exports, "getBadgeById", { enumerable: true, get: function () { return getBadgeById_1.getBadgeById; } });
var updateBadgeById_1 = require("./updateBadgeById");
Object.defineProperty(exports, "updateBadgeById", { enumerable: true, get: function () { return updateBadgeById_1.updateBadgeById; } });
var deleteBadgeById_1 = require("./deleteBadgeById");
Object.defineProperty(exports, "deleteBadgeById", { enumerable: true, get: function () { return deleteBadgeById_1.deleteBadgeById; } });
