"use strict";
/**
 * @module Leaderboards
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMemberLeaderboardsPositionById = exports.getLeaderboardsGamesList = exports.getLeaderboardsMembersList = void 0;
var getLeaderboardsMembersList_1 = require("./getLeaderboardsMembersList");
Object.defineProperty(exports, "getLeaderboardsMembersList", { enumerable: true, get: function () { return getLeaderboardsMembersList_1.getLeaderboardsMembersList; } });
var getLeaderboardsGamesList_1 = require("./getLeaderboardsGamesList");
Object.defineProperty(exports, "getLeaderboardsGamesList", { enumerable: true, get: function () { return getLeaderboardsGamesList_1.getLeaderboardsGamesList; } });
var getMemberLeaderboardsPositionById_1 = require("./getMemberLeaderboardsPositionById");
Object.defineProperty(exports, "getMemberLeaderboardsPositionById", { enumerable: true, get: function () { return getMemberLeaderboardsPositionById_1.getMemberLeaderboardsPositionById; } });
