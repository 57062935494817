"use strict";
/**
 * @module Members
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatronTier = void 0;
/**
 * Map of the patron tiers.
 */
var PatronTier;
(function (PatronTier) {
    PatronTier["TIER1"] = "5D";
    PatronTier["TIER2"] = "10D";
    PatronTier["TIER3"] = "20D";
    PatronTier["TIER4"] = "50D";
})(PatronTier = exports.PatronTier || (exports.PatronTier = {}));
