"use strict";
/**
 * @module Games
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGameCompletionList = exports.getBadgesByGameIdList = exports.getGamesList = void 0;
var getGamesList_1 = require("./getGamesList");
Object.defineProperty(exports, "getGamesList", { enumerable: true, get: function () { return getGamesList_1.getGamesList; } });
var getBadgesByGameIdList_1 = require("./getBadgesByGameIdList");
Object.defineProperty(exports, "getBadgesByGameIdList", { enumerable: true, get: function () { return getBadgesByGameIdList_1.getBadgesByGameIdList; } });
var getGameCompletionList_1 = require("./getGameCompletionList");
Object.defineProperty(exports, "getGameCompletionList", { enumerable: true, get: function () { return getGameCompletionList_1.getGameCompletionList; } });
