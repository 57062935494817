"use strict";
/**
 * @module Featured
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteFeaturedById = exports.updateFeaturedById = exports.createFeatured = exports.getFeaturedList = void 0;
var getFeaturedList_1 = require("./getFeaturedList");
Object.defineProperty(exports, "getFeaturedList", { enumerable: true, get: function () { return getFeaturedList_1.getFeaturedList; } });
var createFeatured_1 = require("./createFeatured");
Object.defineProperty(exports, "createFeatured", { enumerable: true, get: function () { return createFeatured_1.createFeatured; } });
var updateFeaturedById_1 = require("./updateFeaturedById");
Object.defineProperty(exports, "updateFeaturedById", { enumerable: true, get: function () { return updateFeaturedById_1.updateFeaturedById; } });
var deleteFeaturedById_1 = require("./deleteFeaturedById");
Object.defineProperty(exports, "deleteFeaturedById", { enumerable: true, get: function () { return deleteFeaturedById_1.deleteFeaturedById; } });
