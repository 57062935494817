"use strict";
/**
 * @module Update
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateStatus = void 0;
/**
 * Enum representing curator update status.
 */
var UpdateStatus;
(function (UpdateStatus) {
    UpdateStatus["IDLE"] = "idle";
    UpdateStatus["ONGOING"] = "ongoing";
    UpdateStatus["ERROR"] = "error";
})(UpdateStatus = exports.UpdateStatus || (exports.UpdateStatus = {}));
