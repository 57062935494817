"use strict";
/**
 * @module Awards
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAwardById = exports.updateAwardById = exports.getAwardById = exports.createAward = exports.getAwardsList = void 0;
var getAwardsList_1 = require("./getAwardsList");
Object.defineProperty(exports, "getAwardsList", { enumerable: true, get: function () { return getAwardsList_1.getAwardsList; } });
var createAward_1 = require("./createAward");
Object.defineProperty(exports, "createAward", { enumerable: true, get: function () { return createAward_1.createAward; } });
var getAwardById_1 = require("./getAwardById");
Object.defineProperty(exports, "getAwardById", { enumerable: true, get: function () { return getAwardById_1.getAwardById; } });
var updateAwardById_1 = require("./updateAwardById");
Object.defineProperty(exports, "updateAwardById", { enumerable: true, get: function () { return updateAwardById_1.updateAwardById; } });
var deleteAwardById_1 = require("./deleteAwardById");
Object.defineProperty(exports, "deleteAwardById", { enumerable: true, get: function () { return deleteAwardById_1.deleteAwardById; } });
