"use strict";
/**
 * @module Featured
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamPlatform = exports.FeaturedType = void 0;
/**
 * Varions types of featured objects.
 */
var FeaturedType;
(function (FeaturedType) {
    FeaturedType["VIDEO"] = "video";
    FeaturedType["IMAGE"] = "image";
    FeaturedType["NEWS"] = "news";
    FeaturedType["STREAM"] = "stream";
    FeaturedType["CUSTOM"] = "custom";
})(FeaturedType = exports.FeaturedType || (exports.FeaturedType = {}));
var StreamPlatform;
(function (StreamPlatform) {
    StreamPlatform["TWITCH"] = "twitch";
    StreamPlatform["YOUTUBE"] = "youtube";
    StreamPlatform["BILIBILI"] = "bilibili";
})(StreamPlatform = exports.StreamPlatform || (exports.StreamPlatform = {}));
