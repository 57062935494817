enum Theme {
  DEFAULT = 'ash',
  ASH = 'ash', // dark mode
  MEAT = 'meat', // red mode
  DUST = 'dust', // light mode
}

export const media = {
  smallDesktop: '1500px',
  netbooks: '1150px',
  smallNetbooks: '850px',
  tablets: '700px',
  smallTablets: '500px',
  bigPhones: '400px',
};

export { Theme };
